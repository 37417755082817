import React from 'react'
import { connect } from 'react-redux'
import { FetchPreorderMaster } from '../../actions/preorder'
import history from '../../history'
import CardShowDetail from './CardShowDetail'
import Clock from '../Clock'
import Puzzle from './CardPuzzle'
import SuggestProduct from './CardSuggestProduct'
import Panel from '../Panel'
import Menu from '../Menu'
// import Icons from '../Icons'

class ApprovalCard extends React.Component {
  // componentDidMount() {
  //   this.props.FetchPreorderMaster()
  // }

  memberCard () {
    return (
      <div className="ui centered teal card">
        <div className="ui padded grid">
          <div className="center aligned teal row">
            <div className="column"><Clock /></div>
          </div>
        </div>
        <div className="ui mini center aligned brown basic label">為維護社員您的權益，請勿翻拍、截圖給他人使用。</div>
        <div className="content">
          <CardShowDetail />
        </div>
        <Puzzle />
        <SuggestProduct />
      </div>
    )
  }

  openMenu = () => {
    Panel.open({
      component: Menu,
      props: {
        preorder: this.props.preorder
      },
      callback: data => {
        if (data) {
          history.push(data.uri)
        }
      }
    })
  }

  render () {
     return (
     <div>
        {this.memberCard()}
        {/* <Icons/> */}
        {/* <button type='button' className="down-button ui icon teal button" onClick={this.openMenu} ><i class="bars icon"></i></button> */}
      </div>
     )
  }
}

  const mapStateToProps = state => {
    return { preorder: state.preorder }
  }

export default connect(mapStateToProps, { FetchPreorderMaster })(ApprovalCard);
