import React from 'react'
import Modal from '../Modal'
import { Link } from 'react-router-dom'

class CardCheckMember extends React.Component {
  renderAction() {
    return (
      <React.Fragment>
        <Link to={`/cards/display/${this.props.match.params.id}`} className="ui teal button" >是</Link>
        <button onClick={() => window.location ='https://www.hucc-coop.tw/joinus'} className="ui button">不是</button>
      </React.Fragment>
    )
  }

  render() {
    return (
      <Modal
        title="找不到您的線上社員卡！"
        content="請問您是社員嗎？"
        actions={this.renderAction()}
      />
    )
  }
}

export default CardCheckMember
