import React from 'react'
import { connect } from 'react-redux'
import Loader from '../Loader'
import { editMemberCard, getMemberPIDIsExists, getCardByIdnumber } from '../../actions'

class CardFormReview extends React.Component {
  componentDidMount() {
    // chk pid exists
    this.props.getMemberPIDIsExists({ lineuid: this.props.formValues.id, idnumber: this.props.formValues.idnumber })
    if (this.props.member.idnumber !== null) {
      this.props.getCardByIdnumber(this.props.member.idnumber)
    }
  }

  onSubmit = () => {
    const { name: lastname, idnumber, year, month, day, phone: mobilephone, id: lineuid, contactid } = this.props.formValues
    const { memberid, cardno } = this.props.member
    const values = {
      contactid,
      lastname,
      idnumber,
      mobilephone,
      memberid,
      cardno,
      birthdate: `${year}/${month}/${day}`
    }
    this.props.editMemberCard(lineuid, values)
  }

  renderContent = () => {
    const display = (this.props.formValues.year && this.props.formValues.month && this.props.formValues.day) === '' ? 'none' : 'block'
    return (
      <div className="ui relaxed divided list">
        <div className="item">
          <div className="content">
            <div className="header">姓名</div>
            <div className="description">{this.props.formValues.name}</div>
          </div>
        </div>
        <div className="item">
          <div className="content">
            <div className="header">手機</div>
            <div className="description">{this.props.formValues.phone}</div>
          </div>
        </div>
        <div className="item" style={{ display: display }}>
          <div className="content">
            <div className="header">生日</div>
            <div className="description">{`${this.props.formValues.year}-${this.props.formValues.month}-${this.props.formValues.day}`}</div>
          </div>
        </div>
        <div className="item">
          <div className="content">
            <div className="header">身分證字號/居留證號</div>
            <div className="description">{this.props.formValues.idnumber}</div>
          </div>
        </div>
        </div>
    )
  }

  renderFooter() {
    return (
      <div className="ui list">
        <div className="item">以上資料僅限LINE@線上社員卡</div>
        <div className="item">如果您需要更新社員資料，請與我們聯絡：</div>
        <div className="item">
          <i className="phone icon"></i>
          <div className="content">
            聯絡電話│
            <a href="tel:+886-2-29996122">02-2999-6122</a>
            (專人服務時間週一至週五9:00-17:00)
          </div>
        </div>
        <div className="item">
          <i className="mail icon"></i>
          <div className="content">
            Email│
            <a href="mailto:gncoop@hucc-coop.tw">gncoop@hucc-coop.tw</a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.member.pidExists === undefined) {
      return <div></div>
    }
    if (this.props.member.pidExists) {
      return <Loader />
    }
    if (!this.props.member.pidExists) {
      return (
        <div>
          <h5>綁定資料</h5>
          {this.renderContent()}
          <button className="ui button" onClick={this.props.onCancel}>
            返回修改
        </button>
          <button onClick={this.onSubmit} className="ui right teal button">
            確認送出
        </button>
          {this.renderFooter()}
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.cardForm.values,
    member: state.member
  }
}

export default connect(mapStateToProps, { editMemberCard, getMemberPIDIsExists, getCardByIdnumber })(CardFormReview)
