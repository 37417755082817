import React, { useState } from 'react'


export default function Menu(props) {
  const [ preorders ] = useState([ ...props.preorder ])

  const changePage = async e => {
    try {
      e.preventDefault()
      props.close({ uri: `${e.target.value}${e.target.id}` })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      {
        preorders.map(preorder => {
          return <button type='button' className="ui teal button" value='/preorder/' id={preorder.preorderSetId} onClick={changePage}>{preorder.description}</button>
        })
      }
      <br />
    </div>
  )
}