import React from 'react'
import LineAuth from '../LineAuth';
import Loader from '../Loader';
import keys from '../../config/keys';

class TagHomePage extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Loader />
        <LineAuth webUrl='/tags/show' initUrl='/tags/show' errUrl='/linenotfound' redirectUrl={`${keys.default.apiUrl}/tags`} />
      </React.Fragment>
    )
  }
}

export default TagHomePage;