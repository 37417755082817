export default (state=false, action) => {
  switch(action.type) {
    case 'FECTH_MEMBER_CARD':
      return { ...state, ...action.payload } || false
    case 'FECTH_NEW_HISTORY_MEMBER_CARD':
      return { ...state, ...action.payload } || false
    case 'FECTH_CARD_LOG':
      return { ...state, ...action.payload } || false
    case 'EDIT_CARD':
      return { ...state, ...action.payload } || false
    default:
      return state
  }
} 