import React from 'react'
import { Link } from 'react-router-dom'
import Modal from '../Modal'
import liff from '../../util/liffHelper'

class CardTransRemind extends React.Component {
  renderAction() {
    return (
      <React.Fragment>
        <Link to="/cards/trans/list" className="ui teal button" >下一步</Link>
        <button onClick={() => liff.closeWindow()} className="ui button">取消轉移</button>
        <div className="ui grid">
          <div className="sixteen wide left aligned column">
            提醒:線上社員卡轉移完成後，等待30天即可再次轉移。
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderContent() {
    return (
      <div className="ui list">
        <div className="item">進行資料轉移，才能在新的LINE帳號使用線上社員卡。</div>
        <div className="item">請問您要進行資料移轉嗎？要進行線上社員卡轉移，請點擊「下一步」。</div>
      </div>
    )
  }

  render() {
    return (
      <Modal
        title="一張線上社員卡只能綁定一個LINE帳號喔！"
        content={this.renderContent()}
        actions={this.renderAction()}
      />
    )
  }
}

export default CardTransRemind
