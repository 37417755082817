import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import Header from './Header'
import history from '../history'
import Dashboard from './Dashboard'
import CardHistory from './cards/CardHistory'
import CardShow from './cards/CardShow'
import CardBind from './cards/CardBind'
import CardEdit from './cards/CardEdit'
import CardEditCheck from './cards/CardEditCheck'
import CardEditCancel from './cards/CardEditCancel'
import CardChkMember from './cards/CardCheckMember'
import CardDisplay from './cards/CardDisplay'
import CardDisplayNewMember from './cards/CardDiplayNewMember'
import CardPIDExists from './cards/CardPIDExists'
import CardNoPairPID from './cards/CardNoPairPID'
import CardTransList from './cards/CardTransList'
import CardSearchExists from './cards/CardSearchExists'
import CardTransRemind from './cards/CardTransRemind'
import LineNotFound from './LineNotFound'
import TagHomePage from './tags/TagHomePage'
import TagShow from './tags/TagShow'
import PreorderItem from './preorders/PreorderItem'
import OrderComplete from './OrderComplete'


const App = () => {
  return (
    <div className="ui container">
      <Router history={history}>
        <div>
          <Header />
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/tags" exact component={TagHomePage} />
            <Route path="/tags/show" exact component={TagShow} />
            <Route path="/cards/show" exact component={CardShow} />
            <Route path="/cards/search/exists" exact component={CardSearchExists} />
            <Route path="/cards/trans/list" exact component={CardTransList} />
            <Route path="/cards/trans/remind" exact component={CardTransRemind} />
            <Route path="/cards/no/pair/pid" exact component={CardNoPairPID} />
            <Route path="/cards/pid/exists" exact component={CardPIDExists} />
            <Route path="/cards/edit/cancel" exact component={CardEditCancel} />
            <Route path="/cards/edit/check/:id" exact component={CardEditCheck} />
            <Route path="/cards/edit/:id" exact component={CardEdit} />
            <Route path="/cards/bind/:id" exact component={CardBind} />
            <Route path="/cards/check/member/:id" exact component={CardChkMember} />
            <Route path="/cards/display/:id" exact component={CardDisplay} />
            <Route path="/cards/display/new/member" exact component={CardDisplayNewMember} />
            <Route path="/cards/history" exact component={CardHistory} />
            <Route path="/linenotfound" exact component={LineNotFound} />
            <Route path="/preorder/:id" exact component={PreorderItem} />
            <Route path="/order/complete" exact component={OrderComplete} />
            {/* <Route exact component={CardShow} /> */}
          </Switch>
        </div>
      </Router>
    </div>
  )
}

export default App
