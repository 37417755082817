import React from 'react'
import { connect } from 'react-redux'
import { getMemberInfoByLineId } from '../../actions'
import CardForm from './CardForm'
import CardEditFormReview from './CardEditFormReview'

class CardEdit extends React.Component {
  state = { showFormReview: false }

  componentDidMount() {
    this.props.getMemberInfoByLineId(this.props.match.params.id)
  }

  renderContent() {
    if (this.state.showFormReview) {
      return (
        <CardEditFormReview 
          // id={this.props.match.params.id}
          onCancel={() => this.setState({ showFormReview: false })}
        />
      )
    }
    const { name, mobilephone, birthdate, idnumber } = this.props.member
    const year = birthdate === null ? '' : new Date(birthdate).getFullYear().toString()
    const month = birthdate === null ? '' : ((new Date(birthdate).getMonth() + 1) / 10 < 1) ? `0${new Date(birthdate).getMonth() + 1}`.toString() : (new Date(birthdate).getMonth() + 1).toString()
    const day = birthdate === null ? '' : (new Date(birthdate).getDate() / 10 < 1) ? `0${new Date(birthdate).getDate()}`.toString() : new Date(birthdate).getDate().toString()

    return (
    <div>
      <CardForm
          initialValues={{ name, idnumber, year, month, day, phone: mobilephone, id: this.props.match.params.id, contactid: this.props.cards.contactId }}
        // onSubmit={this.onSubmit}
        onCardSubmit={() => this.setState({ showFormReview: true })}
      />
    </div>
    )
  }

  render() {
    if (!this.props.member) {
      return <div>Loading...</div>
    }

    return (
      <div>{this.renderContent()}</div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    member: state.member,
    cards: state.cards
  }
}

export default connect(mapStateToProps, { getMemberInfoByLineId })(CardEdit)
