import React from 'react'
import ReactDOM from 'react-dom'

const Modal = props => {
  const display = props.content === '' ? 'none' : 'block'
  if(props.isHidden) {
    return null
  }

  return ReactDOM.createPortal(
    // props.onDismiss = function = �I��Modal�~���|������Ʊ� ex: onDismiss={() => history.push('/cards/show')}
    <div onClick={props.onDismiss} className="ui dimmer modals visible active">
      <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active">
        {/* <i onClick={() => props.onDismiss()} className='close icon'></i> */}
        <div className="header">{props.title}</div>
        <div className="content" style={{display: display}}>{props.content}</div>
        <div className="actions">{props.actions}</div>
      </div>
    </div>,
    document.querySelector('#modal')
  )
}

export default Modal
  