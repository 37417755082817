import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import SearchBar from '../SearchBar'
import Loader from '../Loader'
import { fetchTransMemberToEmpty, fetchTransMemberAndExists, postAndPutMemberTransfer } from '../../actions/index'
import liff from '../../util/liffHelper'

class CardTransList extends React.Component {
  componentDidMount() {
    this.props.fetchTransMemberToEmpty()
  }

  onButtonClick = async () => {
    this.props.postAndPutMemberTransfer(
      {
        oldLineUid: this.props.memberTransfer.lineuid,
        newLineUid: this.props.oriLine.userId,
        createDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(30, "d").format("YYYY-MM-DD"),
      },
      {
        lineuid: this.props.oriLine.userId,
        contactid: this.props.contactid,
        memberid: this.props.memberTransfer.memberid,
        cardno: this.props.memberTransfer.cardno,
      },
      {
        lineuid: this.props.memberTransfer.lineuid,
        contactid: this.props.memberTransfer.contactid,
        memberid: null,
        cardno: null,
      }
    );
  }

  onSearchSubmit = async cardno => {
    this.props.fetchTransMemberAndExists(this.props.oriLine.userId, cardno, this.props.idnumber)
  }

  renderAdmin() {
    if (!this.props.memberTransfer) {
      return <div></div>
    }
    if (this.props.memberTransfer.lineuid === '') {
      return <div></div>
    }
    return (
      <Fragment>
        <div className="center aligned ui grid">
          <div className="ten wide center aligned column">
            您確定要將線上社員卡轉移到目前的LINE帳號嗎？
          </div>
        </div>
        <div className="center aligned ui grid">
          <div className="ten wide center aligned column">
            <div className="ui buttons">
              <button onClick={this.onButtonClick} className="ui teal button">確定</button>
              <button onClick={() => liff.closeWindow()} className="ui button">取消</button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  renderCardList() {
    if (!this.props.memberTransfer) {
      return <div></div>
    }
    if (this.props.memberTransfer.lineuid === '') {
      return <div></div>
    }
      return (
        <div className="column" key={this.props.memberTransfer.lineuid}>
          <div className="ui centered card">
            <img className="ui medium image" src={this.props.memberTransfer.linepicurl} alt="失效圖片" />
            <div className="content">
              <div className="header">
                {this.props.memberTransfer.nickname}
              </div>
              <div className="description">
                與線上社員卡綁定的帳號
              </div>
            </div>
            </div>
        </div>
      )
  }

  renderCardOldList() {
    if (!this.props.memberTransfer) {
      return <div></div>
    }
    if (this.props.memberTransfer.lineuid === '') {
      return <div></div>
    }
    return (
      <div className="column" key={this.props.oriLine.userId}>
        <div className="ui centered card">
          <img className="ui medium image" src={this.props.oriLine.pictureUrl} alt="失效圖片" />
          <div className="content">
            <div className="header">
              {this.props.oriLine.displayName}
            </div>
            <div className="description">
              目前使用的帳號（沒有綁定線上社員卡）
              </div>
          </div>
        </div>
      </div>
    )
  }

  renderErrMsg() {
    if (this.props.memberTransfer.code === 404) {
      return (
        <div className="ui error message">
          <div className="header">卡號不正確</div>
          <p>請重新輸入</p>
        </div>
      )
    }
    return <div></div>
  }

  render() {
    if (!this.props.memberTransfer) {
      return <Loader />
    }
    return (
      <div>
        <SearchBar onSubmit={this.onSearchSubmit} />
        {this.renderErrMsg()}
        <div className="ui two column grid">
          {this.renderCardList()}
          {this.renderCardOldList()}
        </div>
        {this.renderAdmin(this.props.memberTransfer.lineuid)}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    idnumber: state.member.idnumber,
    contactid: state.member.contactid,
    oriLine: state.line,
    memberTransfer: state.memberTransfer
  }
}

export default connect(mapStateToProps, { fetchTransMemberToEmpty, fetchTransMemberAndExists, postAndPutMemberTransfer })(CardTransList)
