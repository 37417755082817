import React from 'react'
import { connect } from 'react-redux'
import Modal from '../Modal'
import liff from '../../util/liffHelper'
import { sendLineMessage } from '../../actions'


class CardNoBindNoMember extends React.Component {
  sendMsg = () => {
    this.props.sendLineMessage({ type: 'text', text: '我要綁定' })
    setTimeout(() => { liff.closeWindow() }, 2000)
  }
  renderAction() {
    return (
      <React.Fragment>
        <button onClick={() => this.sendMsg()} className="ui teal button">前往個人化綁定</button>
      </React.Fragment>
    )
  }

  render() {
    return (
      <Modal
        title="還沒綁定嗎？"
        content="完成「個人化綁定」後，再點擊選單的「線上社員卡」就能使用囉！"
        actions={this.renderAction()}
      />
    )
  }
}

export default connect(null, { sendLineMessage })(CardNoBindNoMember)
