import React from "react"
import DatePicker from 'react-datepicker'

export const FieldDatePicker = ({ input, placeholder, minDate, maxDate, meta: {touched, error} }) => (
    <React.Fragment>
        <DatePicker
            className="plus-icon"
            dateFormat="yyyy/MM/dd"
            selected={input.value || null}
            onChange={input.onChange}
            minDate={minDate}
            maxDate={maxDate}
            disabledKeyboardNavigation
            placeholderText={placeholder}
        />
        {touched && error && <span>{error}</span>}
    </React.Fragment>

)