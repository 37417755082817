import axios from 'axios'
import keys from '../config/keys'
import history from '../history'
import { encrypt } from '../util/hash'

const API_URL = keys.default.apiUrl
const AUTH_TOKEN = { Authorization: `Bearer ${keys.default.bearerToken}` }

// orders
export const PostOrder = (order, items) => async (dispatch, getState) => {
  await dispatch(PostSalesOrder(order))

  if (getState().orders) {
    await dispatch(PostSalesOrderDetail(items))
    if (getState().orderItem.length !== 0) {
      history.push('/order/complete')
    } else {
      // delete order master
    }
  }
}

// master
export const PostSalesOrder = order => async dispatch => {
  let uri = `${API_URL}/api/salesorder`, payload
  const hashBody = encrypt(JSON.stringify({ ...order }))
  const hashResponse = await axios.post(uri, { value: hashBody }, { headers: AUTH_TOKEN })
  payload = hashResponse.status === 404 ? false : order
  dispatch({ type: 'POST_SALES_ORDER', payload })
}

// detail
export const PostSalesOrderDetail = items => async dispatch => {
  let uri = `${API_URL}/api/salesorder/detail`, payload
  const hashBody = encrypt(JSON.stringify([ ...items ]))
  const hashResponse = await axios.post(uri, { value: hashBody }, { headers: AUTH_TOKEN })
  payload = hashResponse.status === 404 ? false : items
  dispatch({ type: 'POST_SALES_ORDER_ITEMS', payload })
}
