/**
 1. 一次性渲染，無重複使用
 2. 掛在任何內容
    (1)、props 內容屬性
    (2)、component panel內容
    (3)、callback 動作
 */

import React from 'react';
import { render } from 'react-dom';

class Panel extends React.Component {
  state = {
    active: false,
    component: null,
    callback: () => {}
  };

  open = (
    options = {
      props: {},
      component: null,
      callback: () => {}
    }
  ) => {
    const { props, component, callback } = options;
    const _key = new Date().getTime();
    const _component = React.createElement(component, {
      ...props,
      close: this.close,
      delete: this.delete,
      key: _key
    });
    this.setState({
      active: true,
      component: _component,
      callback: callback
    });
  };

  delete = data => {
    // console.log('delete data', data)
    this.state.callback(data);
  }

  close = data => {
    this.setState({
      active: false
    });
    this.state.callback(data);
  };

  render() {
    const _class = {
      true: 'panel-wrapper active',
      false: 'panel-wrapper'
    };
    return (
      <div className={_class[this.state.active]}>
        <div
          className="over-layer"
          onClick={() => {
            this.close();
          }}
        ></div>
        <div className="panel">
          <div className="head">
            <button
              className="close"
              onClick={() => {
                this.close();
              }}
            >
            <i className="x icon"></i>
            </button>
          </div>
          <div className="body">
            {this.state.component}
          </div>
        </div>
      </div>
    );
  }
}

const _div = document.createElement('div');
document.body.appendChild(_div);
const _panel = render(<Panel />, _div);

export default _panel;