import axios from 'axios'
import moment from 'moment'
import history from '../history'
import liffHelper from '../util/liffHelper'
import { encrypt, decrypt } from '../util/hash'
import keys from '../config/keys'
import { getPuzzleYear } from '../util/common'

const API_URL = keys.default.apiUrl
const AUTH_TOKEN = { Authorization: `Bearer ${keys.default.bearerToken}` }

/**
 * 若user tag取不到，就需新增user tag 未處理取得AIM聯絡人 /aim/api/contacts body { systemcode, createdonstart, checkcode }
 * @param {*} param0 
 * @returns 
 */
export const FetchUserTagAndExists = (lineuid) => async (dispatch, getState) => {
  // 查詢user tag 明細
  await dispatch(FetchUserTags(lineuid))
  if (getState().tags.length === 0) {
    // 從lineuid直接新增到detail
    await dispatch(PostTagDetailByLineUid(lineuid))
    if (getState().errors.postUserTags === undefined) {
      // 無錯誤再次查詢
      await dispatch(FetchUserTags(lineuid))
    }
  }
}

/**
 * 取得聯絡ID
 * @param {string} lineuid 
 * @returns 
 */
export const getUserContactId = lineuid => async dispatch => {
  let payload = {}
  let hashLineUid = encrypt(lineuid)
  let hashResponse = await axios.get(`${API_URL}/api/member/contact/${hashLineUid}`, { headers: AUTH_TOKEN })
  if (hashResponse.status === 404) {
    payload = { contactId: false }
  } else {
    payload = JSON.parse(decrypt(hashResponse.data.value))
  }
  dispatch({ type: 'FETCH_CONTACTID_BY_LINEUID', payload })
}

export const PutTagFollow = (lineuid, tagId, follow, now) => async dispatch => {
  let isFollow = follow === 'Y' ? 'follow' : 'unfollow',
  hashLineUid = encrypt(lineuid),
  hashTagId = encrypt(tagId),
  hashBody = encrypt(JSON.stringify({ follow, modifyTime: now }))

  const hashResponse = await axios.put(`${API_URL}/api/tag/${hashTagId}/${isFollow}/${hashLineUid}`, { value: hashBody }, { headers: AUTH_TOKEN })
  if (hashResponse.status === 400) {
    dispatch({ type: 'PUT_TAGS_FOLLOW_ERROR', payload: { postUserTags: false } })
  }
}

/**
 * 新增tag detail By lineuid
 * @param {string} lineUid 
 * @returns 
 */
export const PostTagDetailByLineUid = lineUid => async dispatch => {
  let hashlineUid = encrypt(lineUid)
  let hashResponse = await axios.post(`${API_URL}/api/tags/detail/${hashlineUid}`, {}, { headers: AUTH_TOKEN })
  if (hashResponse.status === 400) {
  dispatch({ type: 'POST_TAGS_ERROR', payload: { postUserTags: false } })
  }
}

/**
 * 新增tag detail
 * @param {OBJ} { tagId, contactId, lineUid, hide, createUser } 
 * @returns 
 */
export const PostTagDetail = ({ tagId, contactId, lineuid, hide, createUser }) => async dispatch => {
  let hashTagId = encrypt(tagId)
  let hashBody = encrypt(JSON.stringify({ contactId, lineuid, hide, createUser }))
  let hashResponse = await axios.post(`${API_URL}/api/tags/${hashTagId}/detail`, { value: hashBody }, { headers: AUTH_TOKEN })
  let { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'POST_TAGS_DETAIL', payload })
}

/**
 * 取得all tags
 * @param {*} sync 
 * @param {*} closed 
 * @returns 
 */
export const FetchTags = (sync, closed) => async dispatch => {
  let hashSync = encrypt(sync)
  let hashClosed = encrypt(closed)
  let hashResponse = await axios.get(`${API_URL}/api/tags/${hashSync}/${hashClosed}`, { headers: AUTH_TOKEN })
  let [ ...payload ] = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_TAGS', payload })
}

/**
 * 取得user tags
 * @param {string} lineuid 
 * @returns 
 */
export const FetchUserTags = lineuid => async dispatch  => {
  let hashLineUid = encrypt(lineuid)
  let hashResponse = await axios.get(`${API_URL}/api/tags/${hashLineUid}`, { headers: AUTH_TOKEN })
  let [ ...payload ] = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_TAGS', payload })
}

export const fetchSalesProduct = (memberId, catNo) => async (dispatch) => {
  const hashCatNo = encrypt(catNo)
  const hashMemberId = encrypt(memberId)
  const hashResponse = await axios.get(`${API_URL}/api/product/largecat/${hashCatNo}/member/${hashMemberId}`, { headers: AUTH_TOKEN })
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_SUGGEST_PRODUCT', payload })
}

/**
 * 3個月EC熱門產品推薦
 * @param {*} catNo 
 */
export const fetchSuggestPorduct = catNo => async (dispatch) => {
  const hashCatNo = encrypt(catNo)
  try {
    const hashResponse = await axios.get(`${API_URL}/api/product/largecat/${hashCatNo}`, { headers: AUTH_TOKEN })
    const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
    dispatch({ type: 'FETCH_SUGGEST_PRODUCT', payload })
  } catch (err) {
    console.log(err)
  }
}

/**
 * 取得共構拼圖
 * @param {string} memberId 社員編號 
 * @param {string} calYear 共構年份
 */
export const fetchMemberPuzzleByYear = (memberId, calYear) => async (dispatch) => {
  const hashId = encrypt(memberId)
  const hashYear = encrypt(calYear)
  const hashResponse = await axios.get(`${API_URL}/api/member/${hashId}/puzzle/by/${hashYear}`, { headers: AUTH_TOKEN })
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_PUZZLE_INFO', payload })
}

/**
 * 寫社員卡log
 * @param {string} lineuid 
 */
export const fetchUserLoginLogs = (lineuid) => async (dispatch) => {
  const hashId = encrypt(lineuid)
  const hashResponse = await axios.post(`${API_URL}/api/members/card/logs/${hashId}`)
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FECTH_CARD_LOG', payload })
}

/**
 * 轉移資料
 * @param {*} param0 { oldLineUid, newLineUid, createDate, endDate }
 * @param {*} param1 { lineuid, contactid, memberid, cardno }
 * @param {*} param2 { lineuid, contactid, memberid, cardno }
 */
export const postAndPutMemberTransfer = ({ ...historyData }, { ...newLineUser }, { ...oldLineUser }) => async (dispatch, getState) => {
  // 新增歷史資料
  await dispatch(postMemberHistory(historyData))
  // 更新新用戶卡號及編號
  await dispatch(putMemberCardNoAndMemberId(newLineUser), 'false')
  // 更新舊用戶卡號及編號為空
  await dispatch(putMemberCardNoAndMemberId(oldLineUser), 'false')
  history.push('/cards/show')
}

/**
 * 新增轉移社員歷史資料
 * @param {*} oldLineUid 
 * @param {*} newLineUid 
 * @param {*} createDate 
 * @param {*} endDate 
 * @return {obj} { insertMemberHistory : true || false }
 */
export const postMemberHistory = ({ oldLineUid, newLineUid, createDate, endDate }) => async (dispatch) => {
  const hashId = encrypt(oldLineUid)
  const hashBody = encrypt(JSON.stringify({ newLineUid, createDate, endDate }))
  const hashResponse = await axios.post(`${API_URL}/api/member/history/${hashId}`, { value: hashBody })
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'INSERT_MEMBER_HISTORYS', payload })
}

/**
 * 更新社員卡號及編號
 * @param {*} lineuid 
 * @param {*} contactid 
 * @param {*} memberid 
 * @param {*} cardno 
 * @param {string} goToCardShows default false，如果true則會挑轉至社員卡頁面
 * @return {obj} { updateMemberIdAndCardNo : true || false }
 */
export const putMemberCardNoAndMemberId = ({ lineuid, contactid, memberid, cardno }, goToCardShows = false) => async (dispatch) => {
  const hashId = encrypt(lineuid)
  const hashBody = encrypt(JSON.stringify({ contactid, memberid, cardno }))
  const hashResponse = await axios.put(`${API_URL}/api/member/card/${hashId}`, { value: hashBody })
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'UPDATE_MEMBER_CARD_AND_MEMBERID', payload })
  if (goToCardShows) {
    history.push('/cards/show')
  }
}

/**
 * 尋找待轉換社員和轉移歷程
 * @param {stinrg} id lineuid
 * @param {string} cardNo 
 * @param {string} idnumber 
 */
export const fetchTransMemberAndExists = (id, cardno, idnumber) => async (dispatch, getState) => {
  // 因為被轉到新社員，所以查詢要改查history的new_lineuid
  await dispatch(fetchTransMember(id, cardno, idnumber))
  // 查詢是否存在
  if (getState().memberTransfer.lineuid !== '') {
    // 新lineuid
    await dispatch(fetchNewMemberCardHistory(getState().memberTransfer.lineuid))
  }
}

export const fetchTransMemberToEmpty = () => (dispatch) => {
  const payload = {
    contactid: '',
    lineuid: '',
    linepicurl: '',
    cardno: '',
    memberid: '',
    idnumber: '',
    nickname: ''
  }
  dispatch({ type: 'FETCH_TRANS_MEMBER_INFO', payload })
}

/**
 * 尋找待轉換社員
 * @param {stinrg} id lineuid
 * @param {string} cardNo 
 * @param {string} idnumber 
 */
export const fetchTransMember = (id, cardno, idnumber) => async (dispatch) => {
  const hashId = encrypt(id)
  const hashBody = encrypt(JSON.stringify({ cardno, idnumber }))
  const hashResponse = await axios.post(`${API_URL}/api/members/transfer/${hashId}`, { value: hashBody })
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_TRANS_MEMBER_INFO', payload })
}

export const getMemberInfoAndCheckIdnumberExists = id => async (dispatch, getState) => {
  await dispatch(getMemberInfoByLineId(id))
  if (getState().member.idnumber !== null) {
    await dispatch(getMemberPIDIsExists({ from: 'getMemberInfoAndCheckIdnumberExists', lineuid: id, idnumber: getState().member.idnumber }))
  } else {
    history.push(`/cards/edit/check/${id}`)
  }
}

export const getMemberInfoByLineIdAndGetCardByIdnumber = (id) => async (dispatch, getState) => {
  await dispatch(getMemberInfoByLineId(id))
  if (getState().member.idnumber !== null) {
    await dispatch(getCardByIdnumber(getState().member.idnumber))
  }
}

export const getCardByIdnumber = (idnumber) => async dispatch => {
  const hashIdnumber = encrypt(idnumber)
  const hashResponse = await axios.get(
    `${API_URL}/api/members/card/info/${hashIdnumber}`
  )
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_CARD_BY_IDNUMBER', payload })
}


export const editMemberCard = (id, formValues) => async dispatch => {
  const hashId = encrypt(id)
  const hashFormValues = encrypt(formValues)
  const hashResponse = await axios.put(
    `${API_URL}/api/members/${hashId}/cardno`,
    { value: hashFormValues }
  )
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'EDIT_CARD', payload })
  history.push('/cards/show')
}

/**
 * 
 * @param {object} idsFrom { from, lineuid, idnumber }
 */
export const getMemberPIDIsExists = idsFrom => async dispatch => {
  const { from, ...ids } = idsFrom
  const hashIds = encrypt(ids)
  const hashResponse = await axios.post(
    `${API_URL}/api/members/pid/exists`,
    { value: hashIds }
  )
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_MEMBER_PID_ISEXISTS', payload })
  if (payload.pidExists && from === 'getMemberInfoAndCheckIdnumberExists') {
    // 準備進入轉移提醒畫面
    history.push('/cards/trans/remind')
  } else if (!payload.pidExists && from === 'getMemberInfoAndCheckIdnumberExists') {
    // 轉移失敗，並沒有相同身分證資料
    history.push('/cards/no/pair/pid')
  } else if (payload.pidExists) {
    // 其他，重複身份
    history.push('/cards/pid/exists')
  }
}

export const getMemberInfoByLineId = id => async dispatch => {
  const hashId = encrypt(id)
  const hashResponse = await axios.get(
    `${API_URL}/api/members/${hashId}`
  )
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_MEMBER_INFO', payload })
}

export const sendLineMessage = msg => async () => {
  const liff = new liffHelper()
  await liff.sendMessages(msg)
}

/**
 * 社員卡明細
 */
export const FetchMemberCard = () => async (dispatch, getState) => {
  if (getState().line.userId !== null) {
    await dispatch(fetchMemberCardHistory(getState().line.userId))
    if (!getState().cards.createDate) {
      await dispatch(fetchMemberCard(getState().line.userId))
      if (getState().cards.statusCode === 200) {
        // 取得拼圖
          await dispatch(fetchMemberPuzzleByYear(getState().cards.memberId, getPuzzleYear(moment().format('YYYY-MM-DD'))))
          // 推薦產品
          if (getState().puzzle.statusCode === 201 || getState().puzzle.statusCode === 203 ) {
            await dispatch(fetchSuggestPorduct(getState().puzzle.ECCatNo))
          }
          // 消費產品
          if (getState().puzzle.statusCode === 202) {
            await dispatch(fetchSalesProduct(getState().cards.memberId, getState().puzzle.ECCatNo))
            // 取無再取推薦產品
            if (!getState().product.image) {
              await dispatch(fetchSuggestPorduct(getState().puzzle.ECCatNo))
            }
          }
        }
      // 新增登入log
      // if (getState().cards.statusCode === 200) {
      //   await dispatch(fetchUserLoginLogs(getState().line.userId))
      // }
    }
  }
}

/**
 * 
 * @param {string} newLineUid 
 */
export const fetchNewMemberCardHistory = newLineUid => async dispatch => {
  const hashLineUid = encrypt(newLineUid)
  const hashResponse = await axios.get(
    `${API_URL}/api/members/card/history/new/${hashLineUid}`
  )
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FECTH_NEW_HISTORY_MEMBER_CARD', payload })
  const { createDate } = payload
  if (createDate) {
    history.push('/cards/search/exists')
  }
}

export const fetchMemberCardHistory = userId => async dispatch => {
  const hashUserId = encrypt(userId)
  const hashResponse = await axios.get(
    `${API_URL}/api/members/card/history/${hashUserId}`
  )
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FECTH_MEMBER_CARD', payload })
  const { createDate } = payload
  if (createDate) {
    history.push('/cards/history')
  }
}

/**
 * get member card
 * @param {string} id
 * @return {object} { statusCode, cardNo } 
 */
export const fetchMemberCard = userId => async dispatch => {
  const hashUserId = encrypt(userId)
  const hashResponse = await axios.get(
    `${API_URL}/api/members/card/${hashUserId}`
  )
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FECTH_MEMBER_CARD', payload })
  const { statusCode } = payload
  if (statusCode === 404) {
    history.push(`/cards/bind/${userId}`)
  } else if (statusCode === 400 || statusCode === 204 || statusCode === 206) {
    history.push(`/cards/check/member/${userId}`)
  }
}

// line liff
export const getLineId = (webUrl, initUrl, errUrl, redirectUrl) => async dispatch => {
  try {
    const liff = new liffHelper()
    const getLineIdFalse = {
          type: "GET_LINE_ID",
          payload: {
            userId: null,
            displayName: null,
            pictureUrl: null,
            statusMessage: null
          }
        }

    const os = liff.getOS()
    const bowser = liff.isInClient()
    if (os === 'web') {
      dispatch(getLineIdFalse)
      history.push(webUrl)
    } else {
      if (bowser) {
        const init = await liff.getInit(redirectUrl)
        if (init) {
          const profile = await liff.getProfile()
          dispatch({ type: 'GET_LINE_ID', payload: profile })
          history.push(initUrl)
        }
      } else {
        dispatch(getLineIdFalse)
        history.push(webUrl)
      }
    }
  } catch (err) {
    await dispatch(postErrorLog({ name: 'INIT_ERROR', type: 'I', lineuid: '', message: JSON.stringify(err), createUser: 'getLineId' }))
    dispatch({ type: 'INIT_ERROR', payload: err })
    history.push(errUrl)
  }
}

/**
 * 新增ERROR至DB
 * @param {OBJ} param0 
 * @returns 
 */
export const postErrorLog = ({ name, type, lineuid, message, createUser }) => async (dispatch) => {
  const hashBody = encrypt(JSON.stringify({ name, type, lineuid, message, createUser }))
  const hashResponse = await axios.post(`${API_URL}/api/error`, { value: hashBody }, { headers: AUTH_TOKEN })
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'POST_ERROR_LOG', payload })
}
