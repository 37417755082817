import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getMemberInfoAndCheckIdnumberExists } from '../../actions/index'
import Modal from '../Modal'

class CardDisplay extends React.Component {
  renderOnClick = () => {
    this.props.getMemberInfoAndCheckIdnumberExists(this.props.match.params.id)
  }
  renderAction() {
    return (
      <React.Fragment>
        <Link to="/cards/display/new/member" className="ui teal button" >是</Link>
        <Link to={`/cards/edit/check/${this.props.match.params.id}`} className="ui button">不是</Link>
      </React.Fragment>
    )
  }
  renderContent() {
    return (
      <div className="ui relaxed divided  list">
        <div className="item">
          <div className="right floated content">
            <Link to="/cards/display/new/member" className="ui teal button">點我</Link>
          </div>
          <div className="content">
            <div className="header">1｜您是3天內入社的社員。</div>
          </div>
        </div>
        <div className="item">
          <div className="right floated content">
            <button onClick={() => { this.renderOnClick() }} className="ui teal button">點我</button>
          </div>
          <div className="content">
            <div className="header">2｜您更換了LINE帳號，先前的帳號已停用。</div>
          </div>
        </div>
        <div className="item">
          <div className="right floated content">
            <Link to={`/cards/edit/check/${this.props.match.params.id}`} className="ui teal button">點我</Link>
          </div>
          <div className="content">
            <div className="header">以上皆非。</div>
          </div>
        </div>

      </div>
    )
  }
  
  render() {
    return (
    <Modal
      title='您符合下列哪一個狀況？'
      content={this.renderContent()}
      // content=''
      // actions={this.renderAction()}
    />
    )
  }
}

export default connect(null, { getMemberInfoAndCheckIdnumberExists })(CardDisplay)
