export default (state = false, action) => {
  switch (action.type) {
    case 'FETCH_MEMBER_INFO':
      return { ...state, ...action.payload } || false
    case 'FETCH_MEMBER_PID_ISEXISTS':
      return { ...state, ...action.payload } || false
    case 'FETCH_CARD_BY_IDNUMBER':
      return { ...state, ...action.payload } || false
    case 'UPDATE_MEMBER_CARD_AND_MEMBERID':
      return { ...state, ...action.payload } || false
    case 'INSERT_MEMBER_HISTORY':
      return { ...state, ...action.payload } || false
    case 'FETCH_CONTACTID_BY_LINEUID':
      return { ...state, ...action.payload} || false
    default:
      return state
  }
} 