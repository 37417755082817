import React from 'react'
import { connect } from 'react-redux'

class SuggestProduct extends React.Component {
  renderContent() {
    return (
      <React.Fragment>
        <div className="content">
          <div className="ui center aligned one column grid">
            <div className="ui one column row">
              <div className="puzzle-text label-height column">推薦您試試…</div>
              <div className="puzzle-text label-height column">{this.props.product.name}</div>
              <div className="column">
                <img className="ui small image label-height  column" src={this.props.product.image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    if (this.props.product.image) {
      return (
        this.renderContent()
      )
    }
    return <div></div>
  }
}

const mapStateToProps = state => {
  return (
    {
      product: state.product
    }
  )
}

export default connect(mapStateToProps)(SuggestProduct)
