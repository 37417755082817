import React from 'react'
import { connect } from 'react-redux'

class Header extends React.Component {
  chkMemberId () {
    if (this.props.memberId === null || this.props.memberId === undefined) {
      return null
    }
    return <h3>社員編號:{this.props.memberId}</h3>
  }

  render () {
    return (
      <div className="ui two teal inverted secondary menu">
        <div className="left item">{this.chkMemberId()}
        </div>
        <div className="right item">
          <img className="ui mini avatar image" src={this.props.pictureUrl} alt="img" />
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return { 
    memberId: state.cards.memberId,
    pictureUrl: state.line.pictureUrl
   }
}
export default connect(mapStateToProps)(Header);