import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import uuid from 'react-uuid'
import moment from 'moment'
import { TransitionGroup, CSSTransition } from 'react-transition-group'


import Panel from '../Panel'
import Carts from '../Carts'
import PreorderForm from './PreorderForm'

import keys from '../../config/keys'
import { FetchPreorderDetail, AddProductCart } from '../../actions/preorder'
import { PostOrder } from '../../actions/salesorder'

class PreorderItem extends React.Component {
  state = {
    cartItems: [],
    cartNumber: 0
  }

  componentDidMount() {
    this.props.FetchPreorderDetail(this.props.match.params.id)
  }

  // 處理菜籃
  handleCartItems = (_cartItems, product) => {
    // console.log('handleCartItems ', _cartItems, product)
    // findindex
    const _index = _cartItems.findIndex(p => p.stockid === product.stockid);
    if (_index === -1) {
      // add
      toast.success(`新增數量，筆數 : ${product.qty}`)
      _cartItems.push(product)
    } else if (product.qty === 0) {
      // del
      toast.success('刪除數量，筆數 : 1')
      _cartItems.splice(_index, 1)
    } else {
      // update
      toast.success(`更新數量，筆數 : ${product.qty}`)
      _cartItems.splice(_index, 1, product)
    }

    this.setState({ cartNumber: this.updateCartNumber(_cartItems) })
    this.setState({ cartItems: _cartItems })
  }

  // 處理下拉選單
  handleChange = async(e) => {
    const id = e.target.id, value = e.target.value, name = e.target.name
    const _cartItems = [ ...this.state.cartItems ]
    let product = { stockid: id, qty: parseInt(value) }
    // console.log('_cartItems ', _cartItems)

    // map
    this.props.preorderItem.map(p => p[name] === product[name] ? product = { ...product, ...p } : null)

    this.handleCartItems(_cartItems, product)
  }

  // 更新菜籃數量
  updateCartNumber = carts => {
    const cartNum = carts
      .map(cart => cart.qty)
      .reduce((a, value) => a + value, 0);
    return cartNum
  }

  // 開啟選單
  openCart = () => {
    Panel.open({
      component: Carts,
      props: {
        cartItems: this.state.cartItems
      },
      callback: data => {
        if (data) {
          if (data.type === 'delete') {
            const _panelCartItems = [ ...this.state.cartItems ],
            product = { stockid: data.stockid, qty: data.qty }
            this.handleCartItems(_panelCartItems, product)
          }
          else if (data.type === 'carts') {
            console.log('cartItems ', data.cartItems)
            console.log('this.state.cartItems ', this.state.cartItems)

          }
        }
      }
    })
  }

  // 選擇產品數量
  selectValue = preorder => {
    let value = 0
    this.state.cartItems.find(cart => {
      if (cart.stockid === preorder.stockid){
        // console.log('options cart.qty', cart.qty)
        value = cart.qty
      }
      return value
    })
  }

  

  renderContent() {
    return (
      <div className="ui relaxed divided list preorder-list">
        <div className="ui divided items">
          <TransitionGroup component={null}>
          {
            this.props.preorderItem.map(preorder => {

              return (
                <CSSTransition classNames="preorder-item" key={preorder.stockid} timeout={500}>
                  <div className="item">
                    <div className="image preorder-image">
                      <img src={`${keys.default.productImgUrl}/${preorder.imagePreview}`} alt="失效圖片" />
                    </div>
                    <div className="content">
                      <h1 className="header">{preorder.productName}</h1>
                      <div className="meta">{preorder.capacity}</div>
                      <div className="description">
                        <p>
                        {preorder.veggie === '葷' ? <div className="ui red horizontal small label">{preorder.veggie}</div> : <div className="ui green horizontal small label">{preorder.veggie}</div> }
                        {preorder.temperate === '常溫' ? <div className="ui teal horizontal small label">{preorder.temperate}</div> : <div className="ui blue horizontal small label">{preorder.temperate}</div> }
                        </p>
                      </div>
                      <div className="extra">
                        <div className="right floated content">
                      </div>
                    <p className="price"><i className="dollar sign icon"></i>{preorder.deprice}</p>
                    </div>
                      <select
                        className="ui fluid dropdown"
                        id={preorder.stockid}
                        name="stockid"
                        value={this.state.cartItems.findIndex(cart => cart.stockid === preorder.stockid) === -1 ? 0 : this.state.cartItems[this.state.cartItems.findIndex(cart => cart.stockid === preorder.stockid)].qty }
                        onChange={this.handleChange}
                      >
                        <option value="0">選擇</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                  </div>
                </div>
                </CSSTransition>
              )
            })
          }
          </TransitionGroup>
        </div>
      </div>
    )
  }

  shoppingBasketIcon () {
    return (
      <button
        type='button'
        className="down-button ui icon teal button"
        onClick={this.openCart}
      >
        <i class="shopping basket icon"></i>
        <span className="cart-number">{this.state.cartNumber}</span>
      </button>
    )
  }

  onPreorderSubmit = formValues => {
    const orderId = uuid()
    const order = {
      id: orderId,
      memberCode: formValues.id,
      type: 1,
      pickDate: moment(formValues.deliveryDate).format('YYYY-MM-DD'),
      erpNo: null,
      recipientName: formValues.name,
      recipientTelephone: formValues.telephone,
      recipientCellphone: formValues.cellphone,
      isFinish: 'Y',
      isAgree: 'Y',
      preorderSetId: this.props.match.params.id,
      customerSignatureUrl: null,
      associateSignatureUrl: null,
      createTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      createUser: this.props.line.userId }
    
    const [ ...items ] = this.state.cartItems.map(item => {
      item.id = uuid()
      item.salesOrderId = orderId
      item.saleprice = item.deprice
      item.subtotal = item.saleprice * item.qty
      item.createTime = moment().format('YYYY-MM-DD HH:mm:ss')
      item.createUser = this.props.match.params.id
      return item
    })

    this.props.PostOrder(order, items)
  }

  render() {
    return (
      <React.Fragment>
        {this.renderContent()}
        <PreorderForm
          cartNumber={this.state.cartNumber}
          pickStartDate={ (this.props.preorderItem.length !== 0) ? this.props.preorderItem[0].pickStartDate : null }
          pickEndDate={ (this.props.preorderItem.length !== 0) ? this.props.preorderItem[0].pickEndDate : null }
          initialValues={{ id: this.props.cards.memberId, name: '', telephone: '', cellphone: '' }}
          onPreorderSubmit={this.onPreorderSubmit}
        />
        {this.shoppingBasketIcon()}
      </React.Fragment>
    )
  }
}

  const mapStateToProps = state => {
    return {
      preorderItem: state.preorderItem,
      cards: state.cards,
      line: state.line
   }
  }

export default connect(mapStateToProps, {
  FetchPreorderDetail,
  AddProductCart,
  PostOrder
})(PreorderItem)
