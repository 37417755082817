import axios from 'axios'
import keys from '../config/keys'
import { encrypt, decrypt } from '../util/hash'

const API_URL = keys.default.apiUrl
const AUTH_TOKEN = { Authorization: `Bearer ${keys.default.bearerToken}` }

export const FetchPreorderMaster = preorderSetId => async dispatch => {
  let payload = []
  let uri = preorderSetId ? `${API_URL}/api/preorderset/${preorderSetId}` : `${API_URL}/api/preorderset`
  // console.log(uri)
  let hashResponse = await axios.get(uri, { headers: AUTH_TOKEN })
  if (hashResponse.status === 404) {
    payload = false
  } else {
    payload = JSON.parse(decrypt(hashResponse.data.value))
    // console.log('FetchPreorderMaster', payload)
  }
  dispatch({ type: 'FETCH_PREORDER_MASTER', payload })
}

export const FetchPreorderDetail = preorderSetId => async dispatch => {
  let payload = []
  let hashPreorderSetId= encrypt(preorderSetId)
  let hashResponse = await axios.get(`${API_URL}/api/preorderset/${hashPreorderSetId}/detail`, { headers: AUTH_TOKEN })
  if (hashResponse.status === 404) {
    payload = false
  } else {
    payload = JSON.parse(decrypt(hashResponse.data.value))
    // console.log('FetchPreorderDetail', payload)
  }
  dispatch({ type: 'FETCH_PREORDER_DETAIL', payload })
}

export const AddProductCart = payload => dispatch => {
  dispatch({ type: 'ADD_PRODUCT', payload })
}