import React from 'react'

const Loader = () => {
  return (
      <div className="ui active inverted dimmer">
        <div className="ui mini text loader">處理中...</div>
    </div>
  )
}

export default Loader