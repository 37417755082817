import React from 'react'
import { connect } from 'react-redux'
import Modal from './Modal'


class LineNotFound extends React.Component {
  render() {
    return (
      <Modal
        title="LINE"
        content={`錯誤訊息: 【${this.props.errCode}】`}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    errCode: state.errors.code
  }
}

export default connect(mapStateToProps)(LineNotFound)