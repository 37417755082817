import React from 'react'
import Modal from '../Modal'
import { connect } from 'react-redux'
import liff from '../../util/liffHelper'

class CardHistory extends React.Component {
  renderAction() {
    return (
      <React.Fragment>
        <button onClick={() => liff.closeWindow()} className="ui teal button">關閉視窗</button>
      </React.Fragment>
    )
  }

  renderContent() {
    return (
      <React.Fragment>
        <div className="ui list">
          <div className="item">{`您的線上社員卡在${this.props.cards.createDate}轉到新的LINE帳號了，麻煩重新登入使用。`}</div>
          <div className="item">如果您有任何疑問，請與我們聯絡：</div>
          <div className="item">
            <i className="phone icon"></i>
            <div className="content">
              聯絡電話│
            <a href="tel:+886-2-29996122">02-2999-6122</a>
              (專人服務時間週一至週五9:00-17:00)
          </div>
          </div>
          <div className="item">
            <i className="mail icon"></i>
            <div className="content">
              Email│
            <a href="mailto:gncoop@hucc-coop.tw">gncoop@hucc-coop.tw</a>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <Modal
        title='請登入新的LINE帳號，才能使用線上社員卡喔！'
        content={this.renderContent()}
        actions={this.renderAction()}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return (
    {
      cards: state.cards
    }
  )
}

export default connect(mapStateToProps)(CardHistory)
