import React from 'react'
import Loader from './Loader'

export class Redirect extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  componentWillMount() {
    window.location = this.props.loc
  }
  render() {
    return <div><Loader /></div>;
    // return (<section>Redirecting...</section>)
  }
}

export default Redirect
