export default (state = {}, action) => {
  switch (action.type) {
    case 'INIT_ERROR':
      return { ...state, ...action.payload } || false
    case 'POST_ERROR_LOG':
      return { ...state, ...action.payload } || false
    case 'POST_TAGS_ERROR':
      return { ...state, ...action.payload } || false
    case 'PUT_TAGS_FOLLOW_ERROR':
      return { ...state, ...action.payload } || false
    default:
      return state
  }
}