import React from 'react'
import Modal from '../Modal'
import liff from '../../util/liffHelper'

class CardEditCancel extends React.Component {
  renderAdmin() {
    return (
      <button onClick={() => liff.closeWindow()} className="ui teal button">關閉視窗</button>
    )
  }

  renderContent() {
    return (
      <div className="ui list">
        <div className="item">您的線上社員卡出了一些問題，請給我們機會為您解答：</div>
        <div className="item">
          <i className="phone icon"></i>
          <div className="content">
            聯絡電話│
            <a href="tel:+886-2-29996122">02-2999-6122</a>
            (專人服務時間週一至週五9:00-17:00)
          </div>
        </div>
        <div className="item">
          <i className="mail icon"></i>
          <div className="content">
            Email│
            <a href="mailto:gncoop@hucc-coop.tw">gncoop@hucc-coop.tw</a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Modal
        title="哎呀！請與我們聯繫！"
        content={this.renderContent()}
        actions={this.renderAdmin()}
        />
      </div>
    )
  }
}

export default CardEditCancel
