import keys from '../config/keys'
const { liff } = window


class LiffHelper {
  constructor() {
    this.initStatus = false
    this.profile = {}
    this.liffInfo = {}
  }

  static closeWindow() {
    liff.closeWindow()
  }

  static openWindow(url, external) {
    liff.openWindow({ url, external })
  }

  isInit() {
    return this.initStatus
  }

  setInit(initStatus) {
    if (typeof initStatus !== 'boolean') throw new Error('setInit should accept only boolean')
    this.initStatus = initStatus
  }

  getLIFFInfo() {
    return this.liffInfo
  }

  getInit(redirectUri) {
    return new Promise((resolve, reject) => {
      liff.init({
        liffId: keys.default.lineLiffId
      })
      .then(() => {
        if (!liff.isLoggedIn()) {
          liff.login({ redirectUri })
        }
        resolve(true)
      })
      .catch((err) => {
        alert(`init err ${err}`);
        reject(false)
      })
    })
  }

  getOS() {
    return liff.getOS()
  }

  isLoggedIn() {
    return liff.isLoggedIn()
  }

  isInClient() {
    return liff.isInClient()
  }

  getVersion() {
    return liff.getVersion()
  }

  getLineVersion() {
    return liff.getLineVersion()
  }

  getAccessToken() {
    return liff.getAccessToken()
  }

  getIDToken() {
    return liff.getIDToken()
  }

  lineLogin() {
    return liff.login()
  }


  getProfile() {
    return new Promise((resolve, reject) => {
      // console.log('this.isInit() ', this.isInit())
      if (!this.profile.userId || liff.isLoggedIn() || liff.isInClient()) {
      liff
        .getProfile()
        .then(pf => {
          this.profile = pf
          resolve(this.profile)
        })
        .catch(err => {
          // console.log(err.code, err.message);
          reject(err)
        })
      } else {
        resolve(this.profile)
      }
    })
  }

  sendMessages(messages) {
    const messagesToSend = Array.isArray(messages) ? messages : [messages]
    return new Promise((resolve, reject) => {
      liff.init({
        liffId: keys.default.lineLiffId
        })
        .then(() => {
          liff
            .sendMessages(messagesToSend)
            .then(() => {
              resolve()
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default LiffHelper