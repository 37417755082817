import React from 'react'
import { connect } from 'react-redux'

class Puzzle extends React.Component {
  renderContent() {
    const style = {
      "font-size": '0.8em'
    }
    const squareStyle = {
      "font-size": '8px'
    }
    return (
      <React.Fragment >
        <div className="content">
          <div className="ui padded grid">
            <div
              className={`${
                this.props.puzzle.statusCode === 201 ? "puzzle-gray" : ""
                } nine wide column`}
            >
              <div className="center aligned image">
                <img
                  className="ui mini puzzle-gray image"
                  src={`https://www.hucc-coop.tw/images/puzzle/puzzle${
                    this.props.puzzle.ECCatNo
                    }${this.props.puzzle.statusCode === 201 ? "_gray" : ""}.png`}
                  alt=""
                />
              </div>
              <div className="content">
                <div
                  className={`puzzle-text center aligned ${
                    this.props.puzzle.statusCode === 201 ? "meta" : "content"
                    }`}
                >
                  {this.props.puzzle.ECCatName}
                </div>
                <div className="center aligned meta" style={style}>
                  {this.starIcon()}
                </div>
                <div className="meta" style={squareStyle}>
                  {this.squareIcon()}
                </div>
              </div>
            </div>
            <div className="seven wide column centered">
              <div className="row">
                <div className="column label-center puzzle-text">共購拼圖</div>
                <div className="column label-center puzzle-text">{this.props.puzzle.ECCatName}</div>
                <div className="column label-center puzzle-text">{this.props.puzzle.description}</div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }

  // 10格數 outline = 空星
  squareIcon() {
    const squareLight = ['outline', 'outline', 'outline', 'outline', 'outline', 'outline', 'outline', 'outline', 'outline', 'outline']

    if (this.props.puzzle.statusCode === 201) {
      return (
        <React.Fragment>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
          <i className="gray square outline icon"></i>
        </React.Fragment>
      );
    }

    if (this.props.puzzle.statusCode === 202 || this.props.puzzle.statusCode === 203) {
      for (let i = 0; i < this.props.puzzle.GridAmt; i++) {
        squareLight[i] = ''
      }
      return (
        <React.Fragment>
          <i className={`orange square ${squareLight[0]} icon`}></i>
          <i className={`orange square ${squareLight[1]} icon`}></i>
          <i className={`orange square ${squareLight[2]} icon`}></i>
          <i className={`orange square ${squareLight[3]} icon`}></i>
          <i className={`orange square ${squareLight[4]} icon`}></i>
          <i className={`orange square ${squareLight[5]} icon`}></i>
          <i className={`orange square ${squareLight[6]} icon`}></i>
          <i className={`orange square ${squareLight[7]} icon`}></i>
          <i className={`orange square ${squareLight[8]} icon`}></i>
          <i className={`orange square ${squareLight[9]} icon`}></i>
        </React.Fragment>
      );
    }
  }

  // 星星圖形 outline = 空星
  starIcon() {
    const starLight = ['outline', 'outline', 'outline']

    if (this.props.puzzle.statusCode === 201) {
      return (
        <React.Fragment>
          <i className="gray star outline icon"></i>
          <i className="gray star outline icon"></i>
          <i className="gray star outline icon"></i>
        </React.Fragment>
      );
    }
    
    if (this.props.puzzle.statusCode === 202 || this.props.puzzle.statusCode === 203) {
      for (let i = 0; i < this.props.puzzle.StarAmt; i++) {
        starLight[i] = ''
      }
      return (
        <React.Fragment>
          <i className={`yellow star ${starLight[0]} icon`}></i>
          <i className={`yellow star ${starLight[1]} icon`}></i>
          <i className={`yellow star ${starLight[2]} icon`}></i>
        </React.Fragment>
      );
    }
  }

  render() {
    if (this.props.puzzle) {
      return this.renderContent()
    } 
    return <div></div>
  }
}

const mapStateToProps = state => {
  return { puzzle: state.puzzle }
}

export default connect(mapStateToProps)(
  Puzzle
)
