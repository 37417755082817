import moment from 'moment'

const getMonthDay = month => {
  return moment().add(month, 'M').format('YYYY-MM-DD')
}

const getToday = () => {
  return moment().format('YYYY-MM-DD')
}

const getWeekStartDay = (day) => {
  return moment().add(day, 'd').format('YYYY-MM-DD')
}

const getPuzzleYear = today => {
  const puzzleDate = moment([moment().format('YYYY'), 6, 15])
  if (puzzleDate.diff(today, 'days') < 0 ) {
    return moment().add(1, 'y').format('YYYY')
  }
  return moment().format('YYYY')
}

const getCalendar = () => {
  const calendar = { year: [], month: [], day: []}

  const today = new Date()
  for (let y = 1920; y < today.getFullYear() - 19; y++ ) {
    calendar.year.push(y)
  }

  for (let m = 1; m < 13; m++ ) {
    if (m / 10 < 1) {
      calendar.month.push(`0${m}`)
    } else {
      calendar.month.push(m)
    }
  }

  for (let d = 1; d < 32; d++ ) {
    if (d / 10 < 1) {
      calendar.day.push(`0${d}`)
    } else {
      calendar.day.push(d)
    }
  }
  return calendar
}

const hiddenStr = (str, frontNumber, endNumber) => {
  const hiddenLength = str.length - frontNumber - endNumber
  let hiddenCode = ''
  for (let i=0; i<hiddenLength; i++) {
    hiddenCode += '*'
  }
  return str.substring(0, frontNumber) + hiddenCode + str.substring(str.length - endNumber)
}
 
export {
  getCalendar,
  hiddenStr,
  getPuzzleYear,
  getWeekStartDay,
  getToday,
  getMonthDay
}
