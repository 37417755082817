import React from 'react'
import { connect } from 'react-redux'
import Modal from '../Modal'
import liff from '../../util/liffHelper'

class CardSearchExists extends React.Component {
  renderAction() {
    return (
      <React.Fragment>
        <button onClick={() => liff.closeWindow()} className="ui button">關閉視窗</button>
      </React.Fragment>
    )
  }

  renderContent() {
    return (
      <div className="ui list">
        <div className="item">{`請於[${this.props.endDate}]後再進行轉移喔！`}</div>
        <div className="item">
          <i className="phone icon"></i>
          <div className="content">
            聯絡電話│
            <a href="tel:+886-2-29996122">02-2999-6122</a>
            (專人服務時間週一至週五9:00-17:00)
          </div>
        </div>
        <div className="item">
          <i className="mail icon"></i>
          <div className="content">
            Email│
            <a href="mailto:gncoop@hucc-coop.tw">gncoop@hucc-coop.tw</a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Modal
        title="最近30天內曾轉移過線上社員卡。"
        content={this.renderContent()}
        actions={this.renderAction()}
      />
    );
  }
}

const mapStateToProps = state => {
  return (
    {
      endDate: state.cards.endDate
    }
  )
}

export default connect(mapStateToProps)(CardSearchExists)
