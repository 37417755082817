import React from 'react'
import { connect } from 'react-redux'
import { getLineId } from "../actions/index";


class LineAuth extends React.Component {
    constructor(props) {
    super(props);
  }
  async componentDidMount() {
    this.props.getLineId(this.props.webUrl, this.props.initUrl, this.props.errUrl, this.props.redirectUri)
  }


  render() {
    return null
  }
}

export default connect(null, { getLineId })(LineAuth);