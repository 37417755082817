import React from 'react'
import Loader from './Loader'
import LineAuth from './LineAuth'
import keys from '../config/keys'

const Dashboard = () => {
    return (
    <div>
      <Loader />
      <LineAuth 
        webUrl='/cards/show'
        initUrl='/cards/show'
        errUrl='/linenotfound'
        redirectUrl={`${keys.default.apiUrl}`}
        />
    </div>
    )
}

export default Dashboard