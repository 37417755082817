import React from 'react'

class SearchBar extends React.Component {
  state = { term: '' }

  onClickSubmit(event) {
    event.preventDefault()

    // 回傳
    this.props.onSubmit(this.state.term)
  }

  render() {
    return (
      <div className="ui placeholder segment">
        <div className="ui icon header">
          <i className="search icon"></i>
          請輸入您的社員卡號
        </div>
        <form className="ui form">
          <div className="field">
            <div className="ui search">
              <div className="ui icon input">
                <input
                  className="prompt"
                  type="text"
                  placeholder="社員卡號(13碼)"
                  value={this.state.term}
                  onChange={(e) =>
                    this.setState({ term: e.target.value.trim() })
                  }
                />
                <button
                  onClick={(event) => this.onClickSubmit(event)}
                  className="ui button"
                >
                  <i className="search icon" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

}

export default SearchBar