import React from 'react'

class Clock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      time: new Date()
    }
  }

  // this.timerId save setInterval
  componentDidMount() {
    this.timerId = setInterval(this.update, 1000)
  }

  // use clearImterval to clear this.timerId
  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  update = () => {
    this.setState({
      time: new Date()
    })
  }

  render() {
    const YY = this.state.time.getFullYear()
    const mm = this.state.time.getMonth() + 1
    const dd = this.state.time.getDate()
    const h = this.state.time.getHours()
    const m = this.state.time.getMinutes()
    const s = this.state.time.getSeconds()
    return (
      <React.Fragment>
          {`${YY}-${(mm < 10 ? '0' + mm : mm)}-${(dd < 10 ? '0' + dd : dd)} ${(h % 12 < 10 ? '0' + h % 12 : h % 12)}:${(m < 10 ? '0' + m : m)}:${(s < 10 ? '0' + s : s)} ${h < 12 ? 'am' : 'pm'} `}
      </React.Fragment>
    )
  }
}

export default Clock
