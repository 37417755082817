import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { parseISO } from 'date-fns'
import { FieldDatePicker } from '../Datepicker'

const phoneFormat = value => value.replace(/[^\d]/g, '')

class PreorderForm extends React.Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      )
    }
  }

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`
    const disabled = input.name === 'id' ? 'ui disabled input' : ''
    const readOnly = input.name === 'id' ? 'readonly' : ''
    const display = input.name === 'id' ? 'none' : 'block'

    return (
      <div className={className}>
        <h2>{label}</h2>
        <input {...input} className={disabled} style={{ display: display }} readOnly={readOnly} placeholder="請填寫必要資訊..." autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.props.handleSubmit(this.props.onPreorderSubmit)} id="preorderContent" className="ui form error preorderForm" >
          <div className="title">訂購人資訊</div>
        {/* <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error" > */}
          <Field name="id" component={this.renderInput} />
          <Field name="name" component={this.renderInput} label="訂購人" required />
          <Field name="telephone" component={this.renderInput} normalize={phoneFormat} label='市內電話' />
          <Field name="cellphone" component={this.renderInput} normalize={phoneFormat} label="手機" />
          <div className="field">
            <h2>預計取貨日</h2>
          </div>
          <Field
            name="deliveryDate"
            component={FieldDatePicker}
            placeholder="DD-MM-YYYY"
            minDate={this.props.pickStartDate ? parseISO(this.props.pickStartDate, 1) : null }
            maxDate={this.props.pickEndDate ? parseISO(this.props.pickEndDate, 1) : null }
            required
          />
          {this.props.cartNumber === 0 ? null : <button className="ui teal button on-submit">送出預購表單</button> }
        </form>
      </React.Fragment>
    )
  }
}

const validate = formValues => {
  const error = {}
  if (!formValues.name) {
    error.name = '請需填寫這個欄位'
  }
  if (!formValues.telephone) {
    error.telephone = '請需填寫這個欄位'
  }
  if (!formValues.cellphone) {
    error.cellphone = '請需填寫這個欄位'
  }
  if (!formValues.deliveryDate) {
    error.deliveryDate = '請需選取這個欄位'
  }
  return error
}

function mapStateToProps(state) {
  return {
    preorder: state.preorder
  }
}

PreorderForm = connect(mapStateToProps)(PreorderForm)

export default reduxForm({
  form: 'preorderForm',
  validate,
  destroyOnUnmount: false
})(PreorderForm)
