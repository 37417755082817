import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import memberCardReducer from './memberCardReducer'
import memberReducer from './memberReducer'
import lineReducer from './lineReducer'
import memberTransReducer from './memberTransReducer'
import puzzleReducer from './puzzleReducer'
import productReducer from './productReducer'
import tagReducer from './tagReducer'
import errReducer from './errReducer'
import preorderSetReducer from './preorderSetReducer'
import preorderSetItemReducer from './preorderSetItemReducer'
import cartReducer from './cartReducer'
import orderReducer from './orderReducer'
import orderItemReducer from './orderItemReducer'

export default combineReducers({
  cards: memberCardReducer,
  line: lineReducer,
  form: formReducer,
  member: memberReducer,
  memberTransfer: memberTransReducer,
  product: productReducer,
  puzzle: puzzleReducer,
  tags: tagReducer,
  errors: errReducer,
  preorder: preorderSetReducer,
  preorderItem: preorderSetItemReducer,
  cartItem: cartReducer,
  orders: orderReducer,
  orderItem: orderItemReducer 
})