import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import axios from 'axios'
import { isNationalIdentificationNumberValid, isResidentCertificateNumberValid } from 'taiwan-id-validator'
import { getCalendar } from '../../util/common'
import { encrypt, decrypt } from '../../util/hash'
import keys from '../../config/keys'


const calendar = getCalendar()
const upper = value => value && value.toUpperCase()
const phoneFormat = value => value.replace(/[^\d]/g, '')

class CardForm extends React.Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      )
    }
  }

  renderInput = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`
    const disabled = (input.name === 'id' || input.name === 'contactid') ? 'ui disabled input' : ''
    const readOnly = (input.name === 'id' || input.name === 'contactid') ? 'readonly' : ''
    const display = (input.name === 'id' || input.name === 'contactid') ? 'none' : 'block'

    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} className={disabled} style={{ display: display }} readOnly={readOnly} placeholder="請填寫必要資訊..." autoComplete="off" />
        {this.renderError(meta)}
      </div>
    );
  }

  renderSelector = ({ input, label, engLabel, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`
    return (
      <div className={className}>
        <label>{label}</label>
        <select className="ui fluid dropdown" {...input}>
          <option type="hidden" value="">{label}</option>
          {calendar[engLabel].map(number => <option value={number} key={number}>{number}</option>)}
        </select>
        {this.renderError(meta)}
      </div>
    )
  }

  onSubmit = formValues => {
    this.props.onSubmit(formValues)
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.props.onCardSubmit)} className="ui form error" >
      {/* <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error" > */}
        <Field name="id" component={this.renderInput} />
        <Field name="contactid" component={this.renderInput} />
        <Field name="name" component={this.renderInput} label="姓名" />
        <Field name="phone" component={this.renderInput} normalize={phoneFormat} label='手機' />
        <Field name="idnumber" component={this.renderInput} normalize={upper} label="身分證字號/居留證號" />
        <div className="field">
          <label>生日</label>
        </div>
        <div className="three fields">
          <Field name="year" component={this.renderSelector} label="年" engLabel="year" />
          <Field name="month" component={this.renderSelector} label="月" engLabel="month" />
          <Field name="day" component={this.renderSelector} label="日" engLabel="day" />
        </div>
        
        <button className="ui teal button">確定</button>
      </form>
    )
  }
}

const validate = formValues => {
  const error = {}
  if (!formValues.name) {
    error.name = '請需填寫這個欄位'
  }
  if (!formValues.idnumber) {
    error.idnumber = '請需填寫這個欄位'
  } else {
    if (!isNationalIdentificationNumberValid(formValues.idnumber.toString())) {
      if (!isResidentCertificateNumberValid(formValues.idnumber.toString())) {
        error.idnumber = '身分證字號/居留證 的格式錯誤。'
      }
    }
  }
  if (!formValues.year) {
    error.year = '請需選取這個欄位'
  }
  if (!formValues.month) {
    error.month = '請需選取這個欄位'
  }
  if (!formValues.day) {
    error.day = '請需選取這個欄位'
  }
  return error
}

const asyncValidate = async (formValues, dispatch) => {
  const hashIdnumber = encrypt(formValues.idnumber)
  const hashResponse = await axios.get(`${keys.default.apiUrl}/api/members/card/info/${hashIdnumber}`)
  const { ...payload } = JSON.parse(decrypt(hashResponse.data.value))
  dispatch({ type: 'FETCH_CARD_BY_IDNUMBER', payload })
  if (payload.cardno === null || payload.memberid === null || payload.idnumber === null) {
    throw { idnumber: '查無社員資料，請重新輸入，或於週一至週五上班時間來電02-2999-6122' }
  }
  
}

function mapStateToProps(state) {
  return {
    member: state.member
  }
}

CardForm = connect(mapStateToProps)(CardForm)

export default reduxForm({
  form: 'cardForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['idnumber'],
  destroyOnUnmount: false
})(CardForm)
