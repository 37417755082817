import React from 'react'
import { connect } from 'react-redux'
import Barcode from 'react-barcode'
import { FetchMemberCard } from '../../actions'
import { Redirect } from '../Redirect'


class Profile extends React.Component {
  componentDidMount() {
    this.props.FetchMemberCard()
  }

  card() {
    // line init 失敗
    if (this.props.line.userId === null) {
      return <div>
        <Redirect loc='http://line.me/ti/p/@efp3888k' />
      </div>
    }
    // cards 一開始default給false
    if (!this.props.cards) {
      return <div></div>
    }
    // 已綁定社員
    if (this.props.cards.statusCode === 200) {
      return (
        <div className="ui centered card">
          <Barcode
            value={this.props.cards.cardNo}
            format="CODE39"
            width={1}
            textAlign="center"
          />
        </div>
      )
    }
  }

  render() {
    return <div>{this.card()}</div>
  }
}

  const mapStateToProps = state => {
    return { line: state.line, cards: state.cards}
  }

export default connect(mapStateToProps, { FetchMemberCard })(
  Profile
);
