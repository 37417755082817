import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Redirect } from '../Redirect'
import liff from '../../util/liffHelper'
import { FetchUserTagAndExists, PutTagFollow, sendLineMessage } from '../../actions'

class TagHomePage extends React.Component {
  state = {
    showNormalTags: false,
    showPersonalTags: false
  }

  componentDidMount() {
    if (this.props.lineUid !== null) {
      this.props.FetchUserTagAndExists(this.props.lineUid)
    }
  }

  sendMsg = message => {
    this.props.sendLineMessage(message)
    setTimeout(() => { liff.closeWindow() }, 1000)
  }

  onClickTag = ({ tagId, isFollow }) => {
    let follow = isFollow === 'Y' ? 'N' : 'Y'
    let now = moment().format('YYYY-MM-DD hh:mm:ss')
    this.props.PutTagFollow(this.props.lineUid, tagId, follow, now)
    let tags = this.props.tags.map(tag => {
      tag.isFollow = (tag.tagId === tagId) ? follow : tag.isFollow
    })
    this.setState({ tags })
  }

  getTagsContent () {
    if (this.props.tags) {
      let normalTags = this.props.tags.map(tag => {
        if (tag.isClosed === 'N') {
          let followColor = (tag.isFollow === 'Y') ? 'follow-color' : 'unfollow-color'
          if (tag.isPersonal === 'N') {
            return <button onClick={() => this.onClickTag(tag)} className={`tag-button ${followColor}`} key={tag.tagId}>{tag.tagName}</button>
          }
          return null
        }
        return null
      })

      let personalTags = this.props.tags.map(tag => {
        if (tag.isClosed === 'N') {
          let followColor = (tag.isFollow === 'Y') ? 'follow-color' : 'unfollow-color'
          if (tag.isPersonal === 'Y' && tag.isMember === 'Y') {
            return <button onClick={() => this.onClickTag(tag)} className={`tag-button ${followColor}`} key={tag.tagId}>{tag.tagName}</button>
          }
          return null
        }
        return null
      })

      return (
        <React.Fragment>
          <button className="ui teal basic button" onClick={() => this.setState({ showNormalTags: !this.state.showNormalTags })}><i className="angle down icon"/>點擊下方類型管理追蹤主題</button>
          <div className="tag-content">{ this.state.showNormalTags ? normalTags : '' }</div>
          <button className="ui teal basic button" onClick={() => this.setState({ showPersonalTags: !this.state.showPersonalTags })}><i className="angle down icon"/>社員專屬管理通知</button>
          <div className="tag-content">{ this.state.showPersonalTags ? personalTags : '' }</div>
        </React.Fragment>
      )
    }
    return null
  }

  tagFooter () {
    return (
      <div className='tag-footer'>
        <button onClick={() => this.sendMsg({ type: 'text', text: '我已經完成追蹤綁定' })} className='button follow-color'>完成追蹤設定</button>
      </div>
    )
  }

  tagPage () {
    if (this.props.lineUid === null) {
      return <Redirect loc='http://line.me/ti/p/@efp3888k' />
    } else {
      return (
        <React.Fragment>
          {this.getTagsContent()}
          {this.tagFooter()}
        </React.Fragment>
      )
    }
  }

  render () {
    return (
      <React.Fragment>
        {this.tagPage()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return { lineUid: state.line.userId,
  member: state.member,
  tags: state.tags }
}

export default connect(mapStateToProps, { FetchUserTagAndExists, PutTagFollow, sendLineMessage })(TagHomePage);