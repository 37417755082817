import React, { useState } from 'react'

export default function Menu(props) {
  const [ cartItems, setCartItems ] = useState([ ...props.cartItems ])

  function cartsTotal() {
    const cartTotal = cartItems
      .map(item => item.deprice * item.qty)
      .reduce((a, value) => a + value, 0)
    return cartTotal
  }

  // post order
  const onSubmit = async(e) => {
    e.preventDefault()

    window.location = '#preorderContent'
    props.close({ type: 'carts', cartItems })
    // salesorder { id: 1, type: 1, memberCode, telephone, cellphone, devliveryDate, isFinish, isAgree, erpNo, saleCode, reviceSign, memberSign }
    // props.close({ type: 'redirect', uri: '#preorderContent' })
  }


  const DeleteItem = e => {
    e.preventDefault()
    const id = e.target.id
    const _index = cartItems.findIndex(i => i.stockid === id)
    cartItems.splice(_index, 1)
    setCartItems([ ...cartItems ])

    // 回傳刪除資料
    props.delete({ type:'delete', stockid: id, qty: 0 })
  }

  if (cartItems.length === 0) {
    return null
  }
  return (
    <React.Fragment>
      <div className="carts-list">
        {
          cartItems.map(product => {
            return (
              <div className="content">
                <h3 className="header">{product.productName}</h3>
                <div className="description">
                  <div className="deprice">
                    <i className="dollar sign icon"></i>{`${product.deprice} * ${product.qty}`}
                  </div>
                  <div className="sub">
                    <i className="dollar sign icon"></i>{`${product.deprice * product.qty}`}
                  </div>
                  <button className="button" id={product.stockid} name="stockid" onClick={DeleteItem}>移除 x</button>
                  </div>
                </div>
            )
          })
        }
        <div className="subtotal">
          <div className="sub-content">
            <div className="sub-description">
              總計 : 
              <div className="deprice">
                <i className="dollar sign icon"></i>
                {`${cartsTotal()}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carts-footer">
        <button className="carts-submit" onClick={onSubmit}>填寫資訊</button>
      </div>
    </React.Fragment>
  )
}