import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getMemberInfoByLineIdAndGetCardByIdnumber } from '../../actions'
import Modal from '../Modal'
import Loader from '../Loader'
import { hiddenStr } from '../../util/common'

class CardEditCheck extends React.Component {
  componentDidMount() {
    this.props.getMemberInfoByLineIdAndGetCardByIdnumber(this.props.match.params.id)
  }

  showButton = () => {
    if (this.props.member.cardno === '' || this.props.member.cardno === null || this.props.member.cardno === undefined) {
      return (<Link to='/cards/edit/cancel' className="ui teal button">正確</Link>)
    } 
    return (<Link to={`/cards/edit/${this.props.match.params.id}`} className="ui teal button" >正確</Link>)
  }

  renderAdmin() {
    return (
      <React.Fragment>
        {this.showButton()}
        <Link to={`/cards/edit/${this.props.match.params.id}`} className="ui button" >不正確</Link>
      </React.Fragment>
    )
  }

  renderContent() {
    if (!this.props.member) {
      return (
        <div>
          <Loader />
        </div>
      )
    }
    const { name, birthdate, mobilephone, idnumber } = this.props.member
    return (
      <div className="ui list">
        <div className="item">花您3分鐘確認</div>
        <div className="item">以下資料是否正確？</div>
        <div className="item">綁定資料：</div>
        <div className="item">{(name === null || name === undefined || name === '') ? '姓名：未填寫' : `姓名：${hiddenStr(name,1 ,1)}` }</div>
        <div className="item">{(mobilephone === null || mobilephone === undefined || mobilephone === '') ? '手機：未填寫' : `手機：${mobilephone.replace(/^(.{4})(?:\d+)(.{3})$/, "$1***$2")}`}</div>
        <div className="item">{(birthdate === null || birthdate === undefined || birthdate === '') ? '生日：未填寫' : `生日：${birthdate}`}</div>
        <div className="item">{(idnumber === null || idnumber === undefined || idnumber === '') ? '身分證字號/居留證號：未填寫' : `身分證/居留證號：${idnumber.replace(/^(.{1})(?:\d+)(.{5})$/, "$1****$2")}`}</div>
        <div className="item">若以上資料顯示「未填寫」，請您點擊「不正確」，提供相關的社員資料唷！</div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Modal 
          title="確認您的資料"
          content={this.renderContent()}
          actions={this.renderAdmin()}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { member: state.member, line: state.line }
}

export default connect(mapStateToProps, { getMemberInfoByLineIdAndGetCardByIdnumber })(CardEditCheck)
