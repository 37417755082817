import CryptoJS from 'crypto-js'
import keys from "../config/keys";


const encrypt = (word) => {
  const key = CryptoJS.enc.Utf8.parse(keys.default.secretKey);
  const iv = CryptoJS.enc.Utf8.parse(keys.default.secretIv);
  let encrypted = ''
  if (typeof word == 'string') {
    const srcs = CryptoJS.enc.Utf8.parse(word);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
  } else {
    const data = JSON.stringify(word);
    const srcs = CryptoJS.enc.Utf8.parse(data);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
  }
  return encrypted.ciphertext.toString()
}

// aes 解碼
const decrypt = (word) => {
  const key = CryptoJS.enc.Utf8.parse(keys.default.secretKey);
  const iv = CryptoJS.enc.Utf8.parse(keys.default.secretIv);
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

export { encrypt, decrypt }
