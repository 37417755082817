import React from 'react'
import { Link } from 'react-router-dom'
import Modal from '../Modal'

class CardPIDExists extends React.Component {
  renderAction() {
    return (
      <React.Fragment>
        <Link to="/cards/trans/remind" className="ui teal button" >資料轉移</Link>
      </React.Fragment>
    )
  }

  renderContent() {
    return (
      <div className="ui list">
        <div className="item">請進行線上社員卡轉移。</div>
        <div className="item">為了確保您的權益，請與我們聯繫：</div>
        <div className="item">
          <i className="phone icon"></i>
          <div className="content">
            聯絡電話│
            <a href="tel:+886-2-29996122">02-2999-6122</a>
            (專人服務時間週一至週五9:00-17:00)
          </div>
        </div>
        <div className="item">
          <i className="mail icon"></i>
          <div className="content">
            Email│
            <a href="mailto:gncoop@hucc-coop.tw">gncoop@hucc-coop.tw</a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Modal
        title="資料與他人重複！"
        content={this.renderContent()}
        actions={this.renderAction()}
      />
    )
  }
}

export default CardPIDExists
